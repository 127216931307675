import React, {useMemo, useState} from 'react';
import {graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import LetterFilter from '../component-elements/letter-filter';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import TeaserBrandComponent from '../brands-component/brand-collection-component/teaser-component';
import HeaderBrandComponent from '../brands-component/brand-collection-component/header-component';
import t from '../lib/translate';
import Section from '../component-elements/content-section';

import * as styles from './styles/brands-collection-list.module.scss';

const BrandCollectionTemplate = ({
									 data: {
										 datoCmsBrand: {
											 id,
											 name,
											 fullUrlPath,
											 productsUrlPath,
											 collectionOverview: page,
											 productCollections,
											 occasionProductCollections
										 },
										 datoCmsConfig: config
									 },
									 pageContext
								 }) => {
	const [filterByLetter, setFilterByLetter] = useState();

	const setLetters = (e) => {
		e.preventDefault();
		setFilterByLetter(e.target.value);
	};

	const allCollections = [
		...(occasionProductCollections.filter(
				({displayBelowCollections}) => (displayBelowCollections ?? false) === false)),
		...productCollections,
		...(occasionProductCollections.filter(
				({displayBelowCollections}) => (displayBelowCollections ?? false) === true))
	];

	const allLetters = allCollections
	.filter(({name: n}) => (n !== null && n.length > 0))
	.map(({name: n}) => n.charAt(0).toUpperCase());

	const visibleCollections = useMemo(() => {
		if(!filterByLetter) {
			return allCollections;
		}
		return allCollections.filter(({name: n}) => filterByLetter.includes(n.charAt(0)));
	}, [filterByLetter]);

	return (
			<Layout textcolorHeader="black" textcolorHeaderMobile="black">
				<Breadcrumb
						items={[
							{id, name, url: fullUrlPath},
							{id: 'brand_colletion_page', name: t`breadcrumb_brand_colletion_item_label`}
						]}
				/>

				{(allCollections.length > 0) && (
						<>
							<HeaderBrandComponent name={name} translationkey="brand_colletion_list_headline" />
							<Section noGrid background="Weiß">
								<div className="brand-collections-container">
									<LetterFilter
											active={filterByLetter}
											setLetters={setLetters}
											input={allLetters}
									/>
									<TeaserBrandComponent content={visibleCollections} />
								</div>
							</Section>
						</>
				)}

				{/*
        (datoCmsBrand.occasionProductCollections.length > 0) && (
          <>
            <HeaderBrandComponent name={datoCmsBrand.name} translationkey="brand_colletion_list_occasion_headline" renderAs="h2" />
            <Section noGrid background="Weiß">
              <div className="brand-collections-container">
                <TeaserBrandComponent
                  content={datoCmsBrand.occasionProductCollections}
                />
              </div>
            </Section>
          </>
        )
      */}

				<Section background="Weiß">
					<div className={`container-fluid ${styles.productsRef}`}>
						<Link to={productsUrlPath} className="btn btn-outline">
							{t`brand_products_button_label`}
						</Link>
					</div>
				</Section>

				{page?.content.map((content) => (
						<CmsMulticolumnBrand key={content.id} {...content} />
				))}

				<Helmet
						tags={page?.seoMetaTags}
						metaCanonical={page?.metaCanonical ? page?.metaCanonical : `${fullUrlPath}${config.brandCollectionListPath}`}
						metaRobots={page?.metaRobots}
						title={page?.seo?.title}
						titleFallback={t('brand_colletion_list_seo_title_fallback', name)}
						description={page?.seo?.description}
						descriptionFallback={t('brand_colletion_list_seo_description_fallback', name)}
						url={pageContext.url}
				/>
			</Layout>
	);
};

export default BrandCollectionTemplate;

BrandCollectionTemplate.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	pageContext: PropTypes.objectOf(PropTypes.any).isRequired
};

export const query = graphql`
query brandCollectionQuery($url: String, $lang: String) {
    datoCmsBrand(url: {eq: $url}, locale: {eq: $lang}) {
        ...brandCollectionFragment
        metaCanonical
        metaRobots
    }
    datoCmsConfig {
        brandCollectionListPath
    }
}
`;
