import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import CmsImageComponent from '../../../content-elements/image';
import * as TeasersStyle from './component.module.scss';

const TeaserBrandComponent = ({
  content = [],
}) => (
  <div className={TeasersStyle.brandCollections}>
    {
      content.map((prod) => (
        <div key={prod.id} className={TeasersStyle.brandCollectionItem}>
          {
            prod.fullUrlPath ? (
              <Link to={prod.fullUrlPath}>
                <div className={TeasersStyle.brandCollectionImageContainer}>
                  {
                    prod.teaser ? (
                      <CmsImageComponent
                        image={prod.teaser}
                        className={TeasersStyle.brandCollectionImage}
                      />
                    ) : (
                      <StaticImage src="../../../images/default.png" alt={prod.name} className={TeasersStyle.brandCollectionImage} width={700} height={700} />
                    )
                  }
                  <div className={`${TeasersStyle.brandCollectionImageText} ${TeasersStyle.brandCollectionImageTextFade}`}>
                    <span className={TeasersStyle.brandCollectionTitle}>{prod.name}</span>
                  </div>
                </div>
              </Link>
            ) : (
              <div className={TeasersStyle.brandCollectionImageContainer}>
                {
                  prod.teaser ? (
                    <CmsImageComponent
                      image={prod.teaser}
                      className={TeasersStyle.brandCollectionImage}
                    />
                  ) : (
                    <StaticImage src="../../../images/default.png" alt={prod.name} className={TeasersStyle.brandCollectionImage} width={700} height={700} />
                  )
                }
                <div className={`${TeasersStyle.brandCollectionImageText} ${TeasersStyle.brandCollectionImageTextFade}`}>
                  <span className={TeasersStyle.brandCollectionTitle}>{prod.name}</span>
                </div>
              </div>
            )
          }
        </div>
      ))
    }
  </div>
);

TeaserBrandComponent.defaultProps = {
  content: [],
};

TeaserBrandComponent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default TeaserBrandComponent;
