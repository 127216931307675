import React from 'react';
import PropTypes from 'prop-types';
import t from '../../../lib/translate';
import ContentHeaderCms from '../../../content-elements/contentHeaderCms';

const HeaderBrandComponent = ({
  name,
  translationkey,
  renderAs,
}) => (
  <ContentHeaderCms background="Weiß">
    {renderAs === 'h2' ? <h2>{t(`${translationkey}`, name)}</h2> : <h1>{t(`${translationkey}`, name)}</h1>}
  </ContentHeaderCms>
);

HeaderBrandComponent.defaultProps = {
  name: '',
  translationkey: '',
  renderAs: '',
};

HeaderBrandComponent.propTypes = {
  name: PropTypes.string,
  translationkey: PropTypes.string,
  renderAs: PropTypes.string,
};

export default HeaderBrandComponent;
